const variantStyleDic = {
  contained: 'bg-black text-white shadow-md',
  outlined: 'border',
  main: 'border bg-black text-white'
}

const variantHoverStyleDic = {
  contained: 'hover:shadow-xl',
  outlined: 'hover:bg-gray-50',
  main: 'hover:opacity-80'
}

export default function Button({ uid, children, variant, disabled, onClick, loading, check, className, fullWidth, keepLock, ...props }) {
  variant ||= 'contained'
  const styleClass = variantStyleDic[variant]
  const width = fullWidth ? 'flex' : 'inline-flex'
  const { current, setLoading } = useLoading()

  loading = current && current === uid

  const renderBody = () => {
    if (!loading) return children
    const opacity = variant === 'contained' ? 1 : 0.4

    return (
      <C.Loading
        size={ 20 } sx={ { opacity } }
      />)
  }

  disabled = !!(disabled || current)

  const disabledClass = disabled ? 'opacity-50' : ''
  const hoverStyle = disabled ? 'cursor-not-allowed' : variantHoverStyleDic[variant]

  return (
    <div className={ cn('px-5 py-2 items-center justify-center rounded cursor-pointer text-sm select-none relative transition-all', hoverStyle, width, styleClass, className, disabledClass) }
      onClick={ async e => {
        const shouldReject = () => {
          if (!check) return false
          const field = check.failedAt()
          if (!field) return false
          const { ref } = field
          if (ref) h.scrollTo(ref.current)
          return true
        }
        if (shouldReject()) return
        if (disabled) return
        if (uid) setLoading(uid)
        try {
          if (onClick) await onClick(e)
        } finally {
          if (!uid) return
          if (keepLock) return
          setLoading(null)
        }
      } }
      { ...props }
    >
      { renderBody() }
    </div>
  )
}
